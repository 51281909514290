
.icon-container {
  right: 1px;
  top: 50%;
  z-index: 500;
  padding: .1em;
  color: #02213B;
  position: absolute;
}
.icon-container > i {
  color: #02213B;
}
.container {
  margin: 2rem auto; 
  width: 70%;
}
#highlights {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(17, 5vw);

}
.highlight-title * i {
  margin-left: 1ch;
}

.highlight-title {
  z-index: 10;
  margin-right: 30%;
  background-color: #ffe270;
  padding: .5em;
  z-index: 1000;
  position: absolute;
  bottom: 5%;
  /* cursor: pointer; */
}
.highlight-title > h3 {
  padding: 0;
  font-size: 1.3em;
}

.gallery__img {
  width: 100%;
  height:100%;
  object-fit: fill;
  margin: 0;
}
.grayed {
 filter: grayscale(1)
}

.gallery__item--0 {
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 8;
  position: relative;
 
}

.gallery__item--2 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 8;
  grid-row-end: 13;
  position: relative;

}

.gallery__item--1 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 2;
  grid-row-end: 8;
  position: relative;

}

.gallery__item--3{
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 13;
  grid-row-end: 20;
  position: relative;

}


.gallery__item--4 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 8;
  grid-row-end: 13;
  position: relative;

}

.nav-icon {
  color: white !important;
}
@media only screen and (max-width: 900px) {
  #highlights {
    display: flex; 
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .highlight-title {
    margin-right: 0;
    z-index: 1000;
  }
  .gallery__item--0, .gallery__item--1, .gallery__item--2, .gallery__item--3, .gallery__item--4 {
    padding: 1em 0;
    background-color: transparent;
    border: 0px solid transparent;
  }
}