.headshot > img {
    max-width: 100%;
}
.headshot {

    border-radius: 5px;
    padding: 1em;
    display: flex;
    flex-direction: column;
}
.align-center {
    align-items: center;
}
.about-page * h2, h3 {
    font-size: 1.7em;
}

.color {
    background-color:#B9929F
}
ul.sub-nav {
    list-style-type: none;

}
.faculty-container {
    align-items: center;
}
.faculty-bio {
    background-color: #ffe270;
    padding: .5em
}

.person-container {
    margin: 1em;
    background-color: #f2f2f2;
    box-shadow: 2px 3px 4px rgba(115, 115, 115, .1);
    border-radius: 5px;
}
.sub-nav {
    display: flex; 
    flex-direction: row;
    align-content: center;

}
.sub-nav > li {
    padding: 1ch;
    margin: 1ch
}
.about-page{
    margin-bottom: 15vh;
}

