
.content {
    padding: .5em;
    margin: .5em;
}
.decorative-icon {
    font-size: 5em
}
.examples {
    margin: .5em; 
    padding: .5em; 

}
.example-photo {
    max-width: 100%;
}
.example-section {
 width: 100%;
 margin: .5em;
 padding: .5em;
 border-radius: 5px;

}
.example-section:nth-child(odd){
  background-color:#efefef;

}
.example-title {
    font-size: 2em;
}
.heading {
    margin-left: 5px;
}

.more-icon {
    font-size: 1.2em;
    margin: .3em;
}

.wrapper {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;

  }
.white-nonlink {
    color: white;
    cursor: default;
}
.transform-partner-button {
background-color: #02213B;
color: white;
margin: .75ch;
padding: 1ch;  
border-radius: 10px;
font-size: 1em;
cursor: pointer;
}
.transform-partner-button .white {
    color:white
}