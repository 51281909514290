


.byline {
    font-size: 1em !important;
  
}
.color-event {
    background-color: #a8daec !important;
}
.color-opportunity {
    background-color: #ffe270 !important;
}
.color-news {
    background-color: #02213B !important;
}
.color-job-true {
    background-color: #780019 !important;
}
.color-day-Monday {
    background-color: #c8bfc7 !important;
}
.color-day-Tuesday {
    background-color: #4059AD !important;
}
.color-day-Wednesday {
    background-color: #7ca982 !important;
}
.color-day-Thursday {
    background-color: #e0eec6 !important;
}
.color-day-Friday {
    background-color: #243e36 !important;
}
.date {
    font-size: 1.2em
}
h4:focus {
    outline: 0;
}
.expand-button {
    background-color: #f2f2f2;
    padding: 1ch;

    border-radius: 5px;

}
.flex-grow-1 {
    width: 30%
}
.flex-grow-2 {
  width: 40%
}
.flex-grow-3 {
   width: 50%
}
.flex-grow-4 {
    width: 80%
}
.gallery {
    background-color: #93B1A7 !important;
}
.left-text {
    text-align: left;
    margin-top:5vh
}
.meta-data {
    font-family: monospace;
    font-size: 1rem !important;
}
.news-card {
    margin: 10px;
    padding: 10px;
}
.news-card > .text-container {
    border: 1px solid rgba(115, 115, 115, .1);
    box-shadow: 1px 2px 3px rgba(115, 115, 115, .1)
}
.news-card * .more-icon { 
    position: absolute; 
    right: 3px;
    bottom: 2px;
    padding: .1em;
}
.news-title{
    font-size: 1.4em;
    text-align: left;
    color: #363537 !important

}
.normal {
    font-size: 1rem !important;
}
.shorter {
 object-fit: contain;
 max-height: 100%;
 max-width: 100%;
  margin-top: 1.5em;
}
@media only screen and (max-width: 900px) {
    .news-card {
        width: 100% !important;
        font-size: .75em;
    }
}