.breathing-room {
  margin-left: 1ch
}
.card-photo {
  margin-bottom: 2em;
}
.centered-padding {
  position: absolute;
  bottom: 5%;
  right:45%;
}
.cover-image {
  width: 100%;
  height: 500px;
  margin: 2em;
  border: 5px solid white;
}

.cta-bar {
  position: absolute;
  left: 2em;
  top: auto;
  right: 0;
  bottom: 1em;
}
.dark-button {
  background-color: #02213B;
  color: white;
  box-shadow: 0px 0px #02213B;
  padding: 1rem;
  border-radius: 5px;
}
.dark-button a {
  color:white;
  text-decoration: none;
}
.description {
  margin-bottom: 5em
}
.section-container {
  margin-bottom: 5vh;
}

.smaller-image {
  padding: 1em;
}
.featured-image {
  padding: 1em;
  margin: 1em;
}
.highlight-box {
  padding: 1em;
}
.highlight-text {
  text-align: center;
}
.icon-button {
  padding: 1em;
  font-size: 2em;
  font-weight: 800;
  cursor: pointer;
  animation: smallbounce 0.8s infinite alternate; 
}

label {
  font-size: 1.1em;
}
.light-filter-div {

  padding: 1em;
  margin-bottom: 1ch
}
.media-section {
  margin-top: 3vh
}
.nav-item {
  margin-left: 2vw;
}
.number {
  border-radius: 5%;
  padding: 1em;
  font-size: 2em;
  margin: 0.5em;
  border-bottom: 3px solid black;
}
.project-page {
 padding: .5em;
}
.project-description{
  font-size: 1.2em;
}
.resize {
  max-width:100%;
}
.smaller {
  max-width: 60%;
  max-height: 60%;
}
section.no-margin {
  margin-top: 0
}
.subtitle {
  font-size: 1.1em;
}
.small-button {
  padding: .5em;
}
.text-container {
  position: relative;
}
.title-only {
  font-size: 1.4em;
  text-align: left;
}
.dark-tag {
  margin: .2rem;
  padding: .5rem;
  background-color: rgba(115, 115, 115, .2);
  border-radius: 5px; 
}
.tag-image {
  height: 50px;
  margin: 5px;
}
.video-player {
  width: 560px !important;
  margin: 1ch;

}
@keyframes smallbounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1em);
  }
}
@media  only screen and (max-width: 900px)  { 
  .breathing-room {
    margin-top: 1ch;
    margin-bottom: 1ch;
  }
}
@media only screen and (max-width: 600px){
  .smaller {
    max-width: 100%;
  }
}