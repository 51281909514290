
/* .contrast {
    background-color: #02213B;
    color: white;
    padding: 1em
} */
.bump-h3 {
    margin-top: 2em
}
.filter-div {
    background-color: #02213B;
    padding: 3em;
    margin-top: 5ch;

  }
  .frame {
    border: 4px solid #ffe270;
    box-shadow: 3px 6px #ffe270
  }
.logo {
    height: 2em
}
.margin-all {
    margin: 1em;
}

.partner-button {
    background-color: #e2e2e2;
    margin: .75ch;
    padding: 1ch;  
    border-radius: 10px;
    font-size: 1em;
    cursor: pointer;
}

.tag{
    margin: .2rem;
    padding: .5rem;
    border-radius: 5px; 
    background-color: white;
}
.partner-button-false {
    background-color: #ffe270;
}
#partner-projects {
    margin-top: 10vh
}

.project-card {
    background-color: white;
    padding: 1em;
    border: 1px solid #02213B;
    border-radius: 5px;
    margin: 1em;
}
.smaller {
    font-size: 1.15em;
}

.white-text {
    color: white;
}

@media only screen and (max-width: 768px) {
    .partner-button{
        width: 100% !important;

    }
    .padding-big {
        margin: 5vh;
    }
}