.email-signup * input {
  font-size: 1.1em;
  border-radius: 5px;
  border: 0 transparent;
  padding: .4ch;
}
.email-signup * input[placeholder]{
  content: "your email"
}
.email-signup * button {
  background-color: #e2e2e2;
  padding: 1em;
  color: black;
  margin-left: 2ch;
}
footer {
  background-color: #02213B;
  color: #e2e2e2;
  min-height: 15vh;
}
footer.waves {
  background-color: white;
}
footer * h3 {
  color: white !important;
}
.footer-content {
  padding: 2em;
}
.footer-content h3 {
    font-size: 1.5em;
}
.footer-logo {
  height: 250px;
}
.larger {
  font-size: 1.5rem
}

.light-button {
  background-color: #ffe270;
  color: #02213B;
  box-shadow: 2px 4px #f2f2f2
}
.reset-color {
  background: white;
}
.robot {
  font-family: monospace;

}
.sign-up-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
 text-align: center;

}

.sign-up {

  padding: 1ch;
  color: white;

}
.sign-up-button {
margin-top: 1ch;
background-color: #a8daec;
  box-shadow: 0 0 transparent;
  color: #02213B;
}


