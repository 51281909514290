
 .about-text-mobile {
    display: none;
}
.about-text {
   font-size: 1.1em;
   background-color: #02213B;
   color: #efefef;
   padding: 1ch;

}
body .modal-open {
    background-color: rgba(0, 0, 0, .8) !important
}
.color-variant {
    background-color:white
}


.flipped {
    transform: scaleY(-1) translateY(-1ch)
}
.hero-image {

    border-radius: 5px;
    width: 100%;
    margin-top: 0
}
.home-carousel-icon {
    transform: translateX(1em);
    padding: 1em;

}
.home-page{
    padding-top: 5vh
}
hr {
    margin: 12px 0;
  border: 0;
  text-align: center;
  margin-bottom: 10vh;
}
.icon {
    height: 50px;
    width: 50px;
}
#image-parent-div {
    position: relative;
    animation: fade 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
#icon-child-div {
    position: absolute;
    top:50%;
    right: 1%;
}
.room {
    margin-top: 1em;
    margin-bottom: 1em;
    display: block;
}
.cta-home {
    border-radius: 5px 0px 0px 5px;
    z-index: 50;
    animation: slidein 2s;
    transform: translateX(2em) translateY(5vh);

   
}
.cta-text {
    font-size: 1.2em;
    background-color:#ffe270;
    padding: 1em;
    border: 2px solid #02213B
}

.hero-button {
    background-color:  #ffe270;
    border: 2px solid white;
    box-shadow: 2px 4px white;
}
/* .light-link {
    color: white !important;
} */
.site-title > h1 {
    z-index: 100;
    font-size: 3em;
    color: #02213B;
}
.home-billboard {

    min-height: 80vh;
    margin-top: 0;
    background-size: cover;
}
.homepage-billboard {
    background-color: white;
    min-height: 10vh;
    margin-top: 0;
    background-size: cover;

}
.home-info {
    font-size: 1.1em;
}
.home-sub-info {
    font-size: 1.1em;
    margin-top: 1em;
    margin-bottom: 1em;
    height: 100%;
   
}
.home-sub-info-mobile {
    display: none;
}
.hero-content {
    margin-top: 0;
    background-color: white;
}
.framed {
    margin: 2.2em;

}
.gray {
    background-color: #efefef;
}

.full-width {
    width: 100%;
}
.link-spacing {
    margin-top: 15vh
}
#mission {
   
    margin-bottom: 5em
}
#modal-overlay #modal {
    background-color: #ffe270;

    z-index: 100;
    animation-duration: 2s;
    animation-name: slidein;
    animation-delay: 250ms;
    margin-left: 2em;
    margin-right: 2em;
    border-radius: 5px;
    box-shadow: 3px 4px 5px rgba(115, 115, 115, .9);
}
#modal-overlay {
    width: 100vw;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
#modal-inner {
   padding: 1em;
}
#partners {
    background-color: #f2f2f2;
}
.photo-caption {
    padding: .5em;
    color: rgba(0, 0, 0, .8);
    margin-bottom: 5vh;
    margin-top: 2ch

}

.section {
    margin-top: 5vh;
}

.smaller-text {
    font-size: 1.5rem;
}
.site-sub-info{
    border-radius: 5px;
    padding: .5em
}

.site-title {
    text-align: center;
    padding: .75em;
    height: 100%;
    letter-spacing: 1.5ch;
}

.site-title > p {
    font-size: 1.5em
}

.site-title-mobile{
    display:none;
}
#site-navigation {
    list-style-type: none;
    margin-top: 3rem;
}
#site-navigation.mobile-menu {
    margin-top: 2ch;
    margin-right: 0

}

.sub-billboard {
    min-height: 10vh;
    margin-top: 0;
    background-size: cover;
    /* background: linear-gradient( #a8daec 5%, #f8f8ff 95%); */

}
.tagline {
    font-size: 1.3em;
    border-radius: 4px;
    padding: 1em
}
.waves {
    background-color: #f8f8ff
}
.waves-home {
    background-color: white;
}
.waves-img {
    transform: translateY(-0.5vh)
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
    .about-text {
        display: none !important;
    }
    .about-text-mobile {
        display: inline-block;
        margin: 1ch;
        padding: 1ch;
    }
    .site-title {
        display: none !important;
       }
       .home-billboard
       {
           background: transparent;
       }
       .hero-image, .photo-caption {
           display: none;
       }
       .home-sub-info-mobile {
        display: inline;
    }
 
       .site-title-mobile{
        display: block;
        text-align: center;
    }
}


@media only screen and (max-width: 900px) {
    .about-text {
        display: none !important;
    }
    .about-text-mobile {
        display: inline-block;
        margin: 1ch;
        padding: 1ch;
    }
    .cta-home{
        transform: translateY(0);
    }
    .home-info, .home-sub-info {
        margin: 5px; 
        padding: 5px;
    }
    .home-sub-info-mobile {
        display: inline;
    }
    .home-billboard
    {
        background: transparent;

    }
    .hero-button {
        transform: translateY(0)
    
    }
    .hero-image, .photo-caption
    {
        display: none
    }
    #icon-child-div {
        transform: translateY(-30vh);
        background-color: rgba(255, 255, 255, .5);
    }
    .back-to-top {
        display: none;
    }
    .site-title {
     display: none !important;
    }
    .site-title-mobile{
        display: block;
        text-align: center;
    }
 
    
}

@keyframes slidein {
    from {
      transform: translateX(-100vh)
    
    }
  
    to {
        transform: translateX(2em) translateY(5vh);

    }
  }
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-30px);} 
    60% {transform: translateY(-15px);} 
  }