.slider-icon-container-left {
  top: 50%;
  left: -10%;
  z-index: 500;
  padding: .1em;
  color: #02213B;
  position: absolute;
}

.slider-icon-container-right {
    top: 50%;
    right: -10%;
    z-index: 500;
    padding: .1em;
    color: #02213B;
    position: absolute;
  }
  .slider-icon-container > i {
    color: #02213B;
  }
  .slider {
    position: relative;
  }