.cta {
  margin: 1em;
  padding: 1em;
  background-color:#ffe270;
  margin-top: 0;
}

.color-block-1 {
  background-color: #ffe270;
  padding: 1em;
  color: #02213B;
  border: 1px solid #02213B;
  text-align: center;
}
  #highlights-interns {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      grid-template-rows: repeat(16, 5vw);
      margin-top: 5vh;

  
  }
  .highlight-title-interns * i {
    margin-left: 1ch;
  }
  
  .highlight-title-interns {
    background-color: #ffe270;
    padding: .5em;
    z-index: 1000;
    position: absolute;
    bottom: 0%;
  }
  .highlight-title-interns > h3 {
    padding: 0;
    font-size: 1.3em;

  }
  .intern-container {
    margin: 2rem auto;
    max-width: 70%;

  }
  .intern-details-list-item{
    background-color: #f2f2f2;
    margin: 1ch;
    padding: .5em
  }
  .gallery__img-interns {
    width: 100%;
    height:100%;
    object-fit: fill;
    margin: 0;

  }
  .grayed {
   filter: grayscale(1)
  }
 /* sebastian */
  .gallery__item-interns--0 {
    grid-column-start: 6;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 9;
    position: relative;
   z-index: 100;
  
  }
  /* erica */
  .gallery__item-interns--1 {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 9;
    position: relative;
  
  }
/* fatih */
  .gallery__item-interns--2 {
    grid-column-start: 2;
    grid-column-end: 9;
    grid-row-start: 9;
    grid-row-end: 16;
    position: relative;
  
  }
 /* aaron */
  .gallery__item-interns--3{
    grid-column-start: 5;
    grid-column-end: 10;
    grid-row-start: 11;
    grid-row-end: 16;
    position: relative;
  
  }
  
/* unused */
  .gallery__item-interns--4 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 13;
    grid-row-end: 17;
    position: relative;
  
  }

  .nav-key {
    margin: 1ch
  }
  .no-bullets {
    list-style-type: none;
    list-style-position:inside;
    margin:0;
    padding:0;
  }
  .no-bullets > li {
    margin: 1ch
  }
  .show-more {
    font-size: 1.1em;
    cursor: pointer;
    border: 1px solid lightgray;
    padding: 1em;
    border-radius: 5px;
  }
  .project-item a {
    text-decoration: none;
  }
  .project-item a:hover{
    text-decoration: underline;
    
  }
 .wrap-text {
  overflow-wrap: break-word;
 }
  @media only screen and (max-width: 1100px) {
    #highlights-interns {
      display: flex; 
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    .highlight-title-interns {
      margin-right: 0;
      z-index: 1000;
    }
    .gallery__item-interns--0, .gallery__item-interns--1, .gallery__item-interns--2, .gallery__item-interns--3, .gallery__item-interns--4 {
      padding: 1em 0;
      background-color: transparent;
      border: 0px solid transparent;
    }
    #intern-headers {
      display: none;
    }
  }