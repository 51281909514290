.blue {
    color: #02213B !important;
}
.cta-chip {
    padding: 1ch; 
    min-height: 8vh;
    background-color: #ffe270;
    background-color: #02213B;
    box-shadow: 3px 4px 5px rgba(115, 115, 115, .1);
    z-index: 1000;
}

.chip {
    text-decoration: none !important;
    padding: .5em;
    border-radius: 5px;
    margin-right: .5em;
}

.chip > i {
    margin-left: .5ch;
    margin-right: 0;
}
.close-menu {
   position: absolute;
   top: 10
}

i {
    cursor: pointer;
    margin-right: .5em;
}
.drop-down {
    position: relative;
}
.drop-down > .sub-menu {
    position: absolute;
    width: 10ch;
    left: 0;
    top: 100%;
    z-index: 10;
  
}
.mobile-div {
    position: relative
}
.mobile-logo {
   height: 150px;
}
.mobile-menu {
    background-color: #ffe270;
    margin-top: 0;
    border-radius: 5px;
    width: 100vw;
    height: 101vh;
    border-radius: 5px;
    z-index: 5000;
    position: fixed; top: 0; bottom: 0; left: 0; right: 0;
    overflow: hidden;
    }
.mobile-menu-list {
    background-color: #ffe270;
    z-index: 100;
    width:100%;
    font-size: 2em;
 
}
.mobile-menu-list > a {
    margin: 5px;
    width: 100%;
    padding: 1ch;
    text-decoration: none!important;
   
}
.social-menu {
    border-radius: 5px;
    /* background-color: #780019; */

}
.social-menu-inner {
    /* border: 1px solid #a8daec;
    box-shadow: 2px  4px #a8daec; */
   border-radius: 5px;
}
.social-icon {
    margin: 0.5em;

  }
  .social-icon > a{
    color: white;
    color: #ffe270;
      text-decoration: none !important;
      background-color: transparent !important;
  }
.show-bars-true {
    display: inherit;
    color: white
}
.show-bars-false {
    display: none;
}
.show-pop-out-true {
    display: inherit;
    transform: translateY(-1em)
}
.show-pop-out-false {
    display: none !important;
}
.sub-menu {
    border-radius: 5px;
    margin-top: 1ch;
   
}
.sub-menu > a {
    padding: 1ch;

}
.sub-menu-mobile > a {
    color: #02213B;
}
.sub-menu > a::before {
    content: "↳ "
}
.site-header {
    min-height: 25vh;
    background-color: white;
}

.site-title-mobile > h1 {
color: #02213B;
}
/* Style the navbar */
#navbar {
    overflow: hidden;
  }
nav {
   margin-top: 2ch;

}
.text-left {
    text-align: left;
}
.toolbar {
    padding: .3em;
    margin: 5px;
}
@media only screen and (max-width:600px){
    .chip-text {
        display: none
    }
    .chip {
        background: transparent
    }
    .social-menu{
        background-color: transparent;
    }
    .social-menu-inner {
        border: 0px solid transparent;
        box-shadow: 0px 0px transparent;
    }
}